import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

const Title = ({ title, text }) => {
  return (
    <div className="pb-8">
      <h1 className="text-2xl font-semibold text-grey-800">{title}</h1>
      <p className="text-sm leading-6 text-gray-500">{text}</p>
    </div>
  );
};

export default Title;

import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { Layout } from "../../components/Layout";
import { Title } from "../../components/Title";
import { TestKitCard } from "../../components/Card";
import { NewKitModal } from "../../components/Modal";
import { data } from "../../config/data";
import { listTestkits } from "../../graphql/queries";
import { createTestkits, deleteTestkits } from "../../graphql/mutations";
import { getUserId } from "../../utils/auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardPage() {
  const userId = getUserId();
  const [open, setOpen] = useState(false);
  const [testKits, setTestKits] = useState([]);

  useEffect(() => {
    fetchTestKits();
  }, []);

  async function fetchTestKits() {
    const filters = {
      user: {
        eq: userId,
      },
    };
    const apiData = await API.graphql({
      query: listTestkits,
      variables: { filter: filters },
    });
    const testKitsData = apiData.data.listTestkits.items;
    setTestKits(testKitsData);
  }

  return (
    <>
      <Layout>
        <div className="py-6">
          <div className="mx-auto max-w-md  px-6 sm:max-w-lg lg:max-w-7xl lg:px-8">
            <Title
              title={`Your Test Results`}
              text={
                "Here are the reports for any testing kits you have previously registered."
              }
            />

            <div className="grid gap-8 grid-cols-1 lg:grid-cols-3">
              {testKits.map((item, ix) => (
                <>
                  <TestKitCard key={item.id} item={item} slug="v" />
                  <TestKitCard key={item.id} item={item} slug="o" />
                </>
              ))}

              <div
                className="flex flex-col overflow-hidden rounded-lg shadow-lg cursor-pointer justify-center items-center"
                onClick={() => setOpen(true)}
              >
                <div className="flex-shrink-0">
                  <div className="w-full relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-12 h-12 mx-auto"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <p className="text-xl font-semibold text-gray-900 text-center">
                      Register New Test Kit
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      <NewKitModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}

import { Switch } from "@headlessui/react";
import { useState } from "react";

function ToggleSwitch({ label, checked, onChange }) {
    const [isChecked, setIsChecked] = useState(checked);
    const toggler = () => {
        setIsChecked(!isChecked);
        onChange();
    };
    return (
        <>
            <label className={`block text-sm font-medium text-gray-300`}>{label}</label>

            <div className="mt-1">
                <Switch
                    checked={isChecked}
                    onChange={toggler}
                    className={`${isChecked ? "bg-teal-900" : "bg-teal-700"}
          relative inline-flex h-[32px] w-[68px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                    <span className="sr-only">Use setting</span>
                    <span
                        aria-hidden="true"
                        className={`${isChecked ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[28px] w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                </Switch>
            </div>
        </>
    );
}

export default ToggleSwitch;

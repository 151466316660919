import React from "react";

function TextInput({
  label,
  value = "",
  onChange,
  required = false,
  disabled = false,
  type = "text",
  placeholder = "",
  helper = "",
  dark = false,
  prefix = "",
  clipboard = false,
  copyToClipboard,
}) {
  return (
    <>
      <label
        className={`block text-sm font-medium ${
          required && !value
            ? "text-red-700"
            : dark
            ? "text-gray-300"
            : "text-gray-700"
        }`}
      >
        {label} {required && <span className="text-xs">(required)</span>}
      </label>

      <div className="mt-1">
        <div
          className={`flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ${
            dark
              ? "bg-transparent border-gray-300 focus:border-white focus:ring-white text-white"
              : clipboard
              ? "text-white bg-white/5 ring-inset ring-white/10 shadow-sm"
              : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          } ${
            required &&
            !value &&
            "border-red-700 focus:border-red-700 focus:ring-red-700"
          }`}
        >
          {clipboard && prefix ? (
            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm w-24 bg-white/10">
              {prefix}
            </span>
          ) : prefix ? (
            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
              {prefix}
            </span>
          ) : (
            <></>
          )}
          <input
            type={type}
            placeholder={placeholder}
            className={
              clipboard
                ? `block flex-1 border-0 focus:ring-0 sm:text-sm sm:leading-6 text-gray-500 bg-transparent`
                : `block flex-1 border-0 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent`
            }
            //className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"

            value={value}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value)}
          />
          {clipboard && (
            <span
              onClick={copyToClipboard}
              className="flex select-none m-1 items-center px-3 rounded-md text-gray-500 sm:text-sm ring-1 ring-inset ring-white/10 shadow-sm cursor-pointer"
            >
              Copy
            </span>
          )}
        </div>
      </div>
      {helper && (
        <p
          className={`mt-2 text-sm ${
            dark ? "text-gray-300 accent-pink-500" : "text-gray-500"
          }`}
        >
          {helper}
        </p>
      )}
    </>
  );
}

export default TextInput;

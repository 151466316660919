const Omega3Explainer = () => {
  return (
    <>
      <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4"></div>
      <p className="mb-6">
        The Omega-3 Index is the proportion of long-chain omega-3s,
        eicosapentaenoic acid (EPA) and docosahexaenoic acid (DHA), of all fatty
        acids in your red blood cell membranes. It reflects the omega-3 status
        of your body over the last 4 months, similar to how hemoglobin A1C
        reflects long-term glucose blood levels. As a part of an overall healthy
        lifestyle, an Omega-3 Index in the 8-12% range may help to maintain
        heart, brain, eye and joint health. To increase your Omega-3 Index, eat
        foods rich in EPA and DHA, especially "oily" fish such as those in the
        accompanying table. They can also be obtained from dietary supplements
        (fish, krill, cod liver, algal oils) and functional foods (omega-3
        enriched milk, eggs, etc.).
      </p>
      <p className="mb-6">
        The amount of EPA and DHA needed to raise the Omega-3 Index into the
        desirable range is different for everybody. Many factors – age, sex,
        weight, diet, genetics, smoking habits, medications, and other medical
        conditions – can all influence the body’s response to EPA and DHA.
        Still, we can provide an estimate, based on our own research, of how
        much EPA and DHA you may need to raise your level to the desirable range
        given your current Omega-3 Index level.
      </p>
      <p className="mb-6">
        The other main dietary omega-3 fatty acid, alpha-linoleic acid (ALA), is
        found in walnuts, flax and chia seeds. ALA can be converted to EPA and
        DHA in the body, but this happens at a very low rate in most people. An
        increase in ALA intake will have little to no effect on the Omega-3
        Index.
      </p>
      <p className="mb-6">
        Please consult with your healthcare provider before making any dietary
        changes. If you increase your intake of EPA and DHA, your Omega-3 Index
        will begin to slowly go up within a few days but will continue to change
        for 3-4 months. We recommend that you re-measure your Omega-3 Index in
        3-4 months until you reach the desirable range. Once you reach the
        desirable range for Omega-3 Index, we recommend that you re-test every 6
        months.
      </p>
    </>
  );
};

export default Omega3Explainer;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTestkits = /* GraphQL */ `
  mutation CreateTestkits(
    $input: CreateTestkitsInput!
    $condition: ModelTestkitsConditionInput
  ) {
    createTestkits(input: $input, condition: $condition) {
      id
      barcode
      user
      collected
      status
      testName
      createdAt
      updatedAt
    }
  }
`;
export const updateTestkits = /* GraphQL */ `
  mutation UpdateTestkits(
    $input: UpdateTestkitsInput!
    $condition: ModelTestkitsConditionInput
  ) {
    updateTestkits(input: $input, condition: $condition) {
      id
      barcode
      user
      collected
      status
      testName
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestkits = /* GraphQL */ `
  mutation DeleteTestkits(
    $input: DeleteTestkitsInput!
    $condition: ModelTestkitsConditionInput
  ) {
    deleteTestkits(input: $input, condition: $condition) {
      id
      barcode
      user
      collected
      status
      testName
      createdAt
      updatedAt
    }
  }
`;

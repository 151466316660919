import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "../pages/Login";
import DashboardPage from "../pages/Dashboard";
import ProtectedRoute from "../components/ProtectedRoute";
import RegisterKitPage from "../pages/Register";
import ReportPage from "../pages/Report";
import TestingPage from "../pages/Testing";
import LogoutPage from "../pages/Logout";

function AppRoutes() {
  return (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      <Routes>
        <Route
          exact={true}
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/register/:barcode"
          element={
            <ProtectedRoute>
              <RegisterKitPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/testing"
          element={
            <ProtectedRoute>
              <TestingPage />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/report/:slug/:id"
          element={
            <ProtectedRoute>
              <ReportPage />
            </ProtectedRoute>
          }
        />
        <Route exact={true} path="/" element={<LoginPage />} />
        <Route exact={true} path="/logout" element={<LogoutPage />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;

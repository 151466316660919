import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { Layout } from "../../components/Layout";
import { Title } from "../../components/Title";
import { TextInput, SelectList } from "../../components/Form";
import { listTestkits } from "../../graphql/queries";
import { createTestkits, deleteTestkits } from "../../graphql/mutations";
import { getUserId } from "../../utils/auth";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RegisterKitPage() {
  const navigate = useNavigate();
  const { barcode } = useParams();
  const [postalCode, setPostalCode] = useState("");
  const [collectionDate, setCollectionDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [isComplete, setIsComplete] = useState(false);

  async function register() {
    const userId = getUserId();

    let payload = {
      barcode: barcode,
      collectionDate: collectionDate,
      email: email,
      firstName: firstName,
      lastName: lastName,
      dob: birthDate,
      gender: gender,
      species: null,
      breed: null,
      postalCode: postalCode,
      country: country,
      eatsFish: "Every other week",
      omega3Supplement: "Fish Oil",
    };

    const data = {
      barcode: barcode,
      user: userId,
      collected: collectionDate,
      status: "new",
      testName: "Omega-3",
    };

    await API.graphql({
      query: createTestkits,
      variables: { input: data },
    });

    setIsComplete(true);
  }

  return (
    <>
      <Layout>
        <main>
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              {/* Replace with your content */}

              {isComplete ? (
                <div className="mx-auto max-w-2xl">
                  <Title title={`Registration Complete`} />
                  <p>
                    Please send your test kit to the laboratory and label as
                    'filter paper'. The address is in your kit. Results will
                    take 7-9 working days once received at the laboratory.
                  </p>
                </div>
              ) : (
                <div className="mx-auto max-w-2xl">
                  <Title
                    title={`Register Test Kit`}
                    text={
                      "Please complete the form below to register your new test kit."
                    }
                  />
                  <div className="border-b border-gray-900/10 pb-12 grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <TextInput
                        label="Barcode"
                        type={"text"}
                        value={barcode}
                        disabled={true}
                      />
                    </div>
                    <div className="col-span-1">
                      <TextInput
                        label="Sample Collection Date"
                        type={"date"}
                        value={collectionDate}
                        onChange={setCollectionDate}
                      />
                    </div>

                    <div className="col-span-1">
                      <TextInput
                        label="First Name"
                        type={"text"}
                        value={firstName}
                        onChange={setFirstName}
                      />
                    </div>
                    <div className="col-span-1">
                      <TextInput
                        label="Last Name"
                        type={"text"}
                        value={lastName}
                        onChange={setLastName}
                      />
                    </div>
                    <div className="col-span-1">
                      <TextInput
                        label="Email Address"
                        type={"email"}
                        value={email}
                        onChange={setEmail}
                      />
                    </div>
                    <div className="col-span-1">&nbsp;</div>
                    <div className="col-span-1">
                      <TextInput
                        label="Postal Code"
                        type={"text"}
                        value={postalCode}
                        onChange={setPostalCode}
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectList
                        label="Country"
                        value={country}
                        onChange={setCountry}
                        options={[
                          { value: "USA", name: "USA" },
                          { value: "UK", name: "United Kingdom" },
                        ]}
                      />
                    </div>

                    <div className="col-span-1">
                      <TextInput
                        label="Date of Birth"
                        type={"date"}
                        value={birthDate}
                        onChange={setBirthDate}
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectList
                        label="Gender"
                        value={gender}
                        onChange={setGender}
                        options={[
                          { value: "Male", name: "Male" },
                          { value: "Female", name: "Female" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <a
                      href="/dashboard"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </a>
                    <button
                      type="submit"
                      onClick={register}
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Register
                    </button>
                  </div>
                </div>
              )}
              {/* /End replace */}
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

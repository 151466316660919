import React from "react";

function SelectList({
    label,
    value,
    onChange,
    options = [],
    helper = "",
    required = false,
    dark = false
}) {
    return (
        <>
            <label
                className={`block text-sm font-medium ${
                    required && !value ? "text-red-700" : dark ? "text-gray-300" : "text-gray-700"
                }`}
            >
                {label} {required && <span className="text-xs">(required)</span>}
            </label>
            <div className="mt-1">
                <select
                    className={`block w-full rounded-md shadow-sm sm:text-sm ${
                        dark
                            ? "bg-transparent border-gray-300 focus:border-white focus:ring-white text-white"
                            : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    } ${
                        required &&
                        !value &&
                        "border-red-700 focus:border-red-700 focus:ring-red-700"
                    }`}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                >
                    <option value="">Please select...</option>
                    {options.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
            {helper && (
                <p className={`mt-2 text-sm ${dark ? "text-gray-300" : "text-gray-500"}`}>
                    {helper}
                </p>
            )}
        </>
    );
}

export default SelectList;

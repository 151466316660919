import { Fragment, useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
export default function TestingPage() {
  return (
    <>
      <Layout>
        <main>
          <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
            <div className="text-left py-16">
              <p className="mb-6">
                The following barcodes can be used during testing.
              </p>

              <p className="mb-6">
                Omega-3 Index Basic
                <br />
                USDEMO113 – In completed status with sample results
                <br />
                USDEMO114-124
              </p>
              <p className="mb-6">
                Omega-3 Index Plus
                <br />
                USDEMO201 – In completed status with sample results
                <br />
                USDEMO202-212
              </p>
              <p className="mb-6">
                Omega-3 Index Complete
                <br />
                USDEMO301 – In completed status with sample results
                <br />
                USDEMO302-312 – Available for testing
              </p>

              <p className="mb-6">
                Vitamin D<br />
                USDEMO701 – In completed status with sample results
                <br />
                USDEMO702-712 – Available for testing
              </p>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTestkits = /* GraphQL */ `
  query GetTestkits($id: ID!) {
    getTestkits(id: $id) {
      id
      barcode
      user
      collected
      status
      testName
      createdAt
      updatedAt
    }
  }
`;
export const listTestkits = /* GraphQL */ `
  query ListTestkits(
    $filter: ModelTestkitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestkits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        barcode
        user
        collected
        status
        testName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

import Arrow from "../../Images/arrow.svg";
import moment from "moment";
const Omega3Header = ({
  name,
  dob,
  barcode,
  collectionDate,
  resultDate,
  testResult,
}) => {
  const numberPadding = 100 / 12 / 2;
  const numberWidth = 100 / 12;
  const arrowOffset = numberPadding + (testResult - 1) * numberWidth;

  return (
    <>
      <div className="report rounded-lg overflow-hidden">
        <div className="report__header py-4 px-4 text-lg font-semibold">
          <h2 className="font-semibold leading-6 text-blue-900 text-lg">
            Omega-3 Index Basic Report
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-6 gap-4 px-4 py-4">
          <div className="sm:col-span-2">
            <h2 className="text-3xl font-semibold">Your Omega-3 Index</h2>
            <p className="text-sm">Reference Range*: 2.8% - 15.4%</p>
          </div>
          <div className="sm:col-span-2 relative">
            <div className="rounded-full bg-blue-200 w-36 h-36 flex items-center justify-center text-blue-900 text-3xl font-semibold mx-auto">
              {testResult}%
            </div>
          </div>
          <div className="sm:col-span-2 text-sm">
            <p className="mb-6">
              NAME: {name}
              <br />
              DOB: {moment(dob).format("MM/DD/YYYY")}
              <br />
              ID: {barcode}
            </p>
            <p>
              COLLECTION DATE: {moment(collectionDate).format("MM/DD/YYYY")}
              <br />
              RESULT DATE: {moment(resultDate).format("MM/DD/YYYY")}
              <br />
              ACCOUNT: Consumer
            </p>
          </div>
        </div>
        <div className="relative">
          <div
            className="w-32 grid grid-cols-1"
            style={{
              marginLeft: `${arrowOffset}%`,
            }}
          >
            <div className="-ml-16 w-32">
              <div className="bg-blue-800 text-white text-center text-xs py-2 rounded-md">
                Your Level
              </div>
              <div className="text-center text-blue-800">
                <img
                  src={Arrow}
                  className="rotate-180 h-6 text-blue-800 mx-auto -mt-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="report__gradient py-2 px-4 text-right text-sm">
          Desired Range: 8% - 12%
        </div>
        <div className="grid grid-cols-12 bg-gray-600">
          <div className="text-white text-center text-sm py-2">1%</div>
          <div className="text-white text-center text-sm py-2">2%</div>
          <div className="text-white text-center text-sm py-2">3%</div>
          <div className="text-white text-center text-sm py-2">4%</div>
          <div className="text-white text-center text-sm py-2">5%</div>
          <div className="text-white text-center text-sm py-2">6%</div>
          <div className="text-white text-center text-sm py-2">7%</div>
          <div className="text-white text-center text-sm py-2">8%</div>
          <div className="text-white text-center text-sm py-2">9%</div>
          <div className="text-white text-center text-sm py-2">10%</div>
          <div className="text-white text-center text-sm py-2">11%</div>
          <div className="text-white text-center text-sm py-2">12%</div>
        </div>
      </div>

      <p className="text-xs italic text-gray-600 pt-2">
        * Reference Ranges encompass about 99% of US adults.
      </p>
    </>
  );
};

export default Omega3Header;

import jwt from "jwt-decode";

export const login = ({ email, password }) => {
  let url = `https://donotage.org/?rest_route=/simple-jwt-login/v1/auth`;
  if (email.includes("@")) {
    url += `&email=${email}`;
  } else {
    url += `&username=${email}`;
  }
  url += `&password=${password}`;
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return false;
      }
    })
    .then((responseJson) => {
      if (responseJson.success) {
        const user = jwt(responseJson.data.jwt);
        localStorage.setItem("dnaUser", JSON.stringify(user));
        return responseJson;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
};

export const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("dnaUser"));
  if (user) {
    return user.id;
  } else {
    return null;
  }
};

export const logout = async () => {
  localStorage.removeItem("dnaUser");
  return true;
};

export const isLoggedIn = () => {
  const user = localStorage.getItem("dnaUser");
  if (user) {
    return true;
  } else {
    return false;
  }
};

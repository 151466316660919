import Arrow from "../../Images/arrow.svg";
import moment from "moment";
const VitaminDHeader = ({
  name,
  dob,
  barcode,
  collectionDate,
  resultDate,
  testResult,
}) => {
  const numberPadding = 100 / 7 / 2;
  const numberWidth = 100 / 7;
  const arrowOffset = numberPadding + (testResult / 15 - 1) * numberWidth;
  return (
    <>
      <div className="report rounded-lg overflow-hidden">
        <div className="bg-yellow-500 py-4 px-4 text-lg font-semibold">
          <h2 className="font-semibold leading-6 text-blue-900 text-lg text-white">
            Vitamin D Report
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-6 gap-4 px-4 py-4">
          <div className="sm:col-span-2">
            <h2 className="text-3xl font-semibold">Your Vitamin D</h2>
            <p className="text-sm">Reference Range*: 20 - 80 ng/mL</p>
          </div>
          <div className="sm:col-span-2 relative">
            <div className="rounded-full bg-yellow-500 w-36 h-36 flex items-center justify-center text-white text-3xl font-semibold mx-auto">
              <div className="grid grid-cols-1">
                <div>{testResult}</div>
                <div className="text-sm text-center">ng/mL</div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2 text-sm">
            <p className="mb-6">
              NAME: {name}
              <br />
              DOB: {moment(dob).format("MM/DD/YYYY")}
              <br />
              ID: {barcode}
            </p>
            <p>
              COLLECTION DATE: {moment(collectionDate).format("MM/DD/YYYY")}
              <br />
              RESULT DATE: {moment(resultDate).format("MM/DD/YYYY")}
              <br />
              ACCOUNT: Consumer
            </p>
          </div>
        </div>
        <div className="relative">
          <div
            className="w-32 grid grid-cols-1"
            style={{
              marginLeft: `${arrowOffset}%`,
            }}
          >
            <div className="-ml-16 w-32">
              <div className="bg-blue-800 text-white text-center text-xs py-2 rounded-md">
                Your Level
              </div>
              <div className="text-center text-blue-800">
                <img
                  src={Arrow}
                  className="rotate-180 h-6 text-blue-800 mx-auto -mt-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="report__gradient_vitamin py-2 px-4 text-sm">
          <div className="grid grid-cols-5">
            <div className="col-span-3 text-center">Desired: 30 - 50 ng/mL</div>
            <div className="col-span-2 text-right">Excess {">"} 100 ng/mL</div>
          </div>
        </div>
        <div className="grid grid-cols-7 bg-gray-600">
          <div className="text-white text-center text-sm py-2">15</div>
          <div className="text-white text-center text-sm py-2">30</div>
          <div className="text-white text-center text-sm py-2">45</div>
          <div className="text-white text-center text-sm py-2">60</div>
          <div className="text-white text-center text-sm py-2">75</div>
          <div className="text-white text-center text-sm py-2">90</div>
          <div className="text-white text-center text-sm py-2">105</div>
        </div>
      </div>

      <p className="text-xs italic text-gray-600 pt-2">
        * Reference Range is representative of a normal patient population.
      </p>
    </>
  );
};

export default VitaminDHeader;

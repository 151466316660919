import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { Layout } from "../../components/Layout";
import { Title } from "../../components/Title";
import {
  Omega3Explainer,
  Omega3Header,
  VitaminDExplainer,
  VitaminDHeader,
} from "../../components/Report";
import Omega from "../../Images/omega.webp";
import DnaLogo from "../../Images/dna.webp";
import { listTestkits, getTestkits } from "../../graphql/queries";
import { createTestkits, deleteTestkits } from "../../graphql/mutations";
import { getUserId } from "../../utils/auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ReportPage() {
  const userId = getUserId();
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const [report, setReport] = useState(null);
  const [testName, setTestName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [collectionDate, setCollectionDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [resultDate, setResultDate] = useState("");
  const [testValue, setTestValue] = useState("");
  const [isVitaminD, setIsVitaminD] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadReport();
  }, []);

  useEffect(() => {
    fetchResults();
  }, [barcode]);

  async function loadReport() {
    try {
      const testKitData = await API.graphql(
        graphqlOperation(getTestkits, { id: id })
      );
      const data = testKitData.data.getTestkits;
      setBarcode(data.barcode);
    } catch (err) {
      console.log("error getting test kit:", err);
    }
  }

  async function deleteTest() {
    const testKitData = await API.graphql(
      graphqlOperation(deleteTestkits, { input: { id: id } })
    );
    navigate(`/dashboard`);
  }

  async function fetchResults() {
    if (barcode) {
      fetch("https://api.omegaquant.com/api/results/" + barcode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-functions-key":
            "e-IHAFI9iNM1tJVriQG0Ol7QFNG4meJsS1omV0lpk4fDAzFuaZ4wPw==",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            console.log(data);
            setLoaded(true);
            setTestName(data.testName);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setCollectionDate(data.collectionDate);
            setBirthDate(data.dateOfBirth);
            setResultDate(data.analysisDate);
            if (slug === "v") {
              setIsVitaminD(true);
              setTestValue(data.vitaminDLevel.value);
            } else {
              setTestValue(data.omega3Index.value);
            }
          }
        })
        .catch((error) => {
          setFailed(true);
          setLoaded(true);
          console.log("Error fetching API: ", error);
        });
    }
  }

  return (
    <>
      <Layout>
        {barcode && (
          <main>
            <header className="relative isolate pt-16">
              <div
                className="absolute inset-0 -z-10 overflow-hidden"
                aria-hidden="true"
              >
                <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                  <div
                    className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                    style={{
                      clipPath:
                        "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                    }}
                  />
                </div>
                <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
              </div>

              <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
                <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                  <div className="hidden sm:flex items-center gap-x-6">
                    <img
                      src={DnaLogo}
                      alt=""
                      className="h-12 w-12 flex-none rounded-full ring-1 ring-gray-900/10"
                    />
                    <h1>
                      <div className="text-sm leading-6 text-gray-500">
                        <span className="text-gray-700">{barcode}</span>
                      </div>
                      <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                        {firstName} {lastName}
                      </div>
                    </h1>
                  </div>
                  <div className="flex items-center gap-x-4 sm:gap-x-6">
                    &nbsp;
                  </div>
                </div>
              </div>
            </header>

            {!loaded ? (
              <div className="py-16">
                <ReactLoading
                  type="bars"
                  color="#000"
                  height={"40px"}
                  className="mx-auto"
                />
              </div>
            ) : failed ? (
              <div className="text-center py-16">
                We're still awaiting your test results, please check back again
                soon.
              </div>
            ) : (
              <>
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none">
                    {/* Results */}
                    <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                      {isVitaminD ? (
                        <VitaminDHeader
                          name={firstName + " " + lastName}
                          dob={birthDate}
                          barcode={barcode}
                          collectionDate={collectionDate}
                          resultDate={resultDate}
                          testResult={testValue}
                        />
                      ) : (
                        <Omega3Header
                          name={firstName + " " + lastName}
                          dob={birthDate}
                          barcode={barcode}
                          collectionDate={collectionDate}
                          resultDate={resultDate}
                          testResult={testValue}
                        />
                      )}

                      {isVitaminD ? <VitaminDExplainer /> : <Omega3Explainer />}
                    </div>
                    <div className="text-right pt-2">
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this test kit?"
                            )
                          )
                            deleteTest();
                        }}
                        className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Delete Test Kit
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </main>
        )}
      </Layout>
    </>
  );
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Modal } from "../../components/Modal";

const NewKitModal = ({ open = false, onClose }) => {
  const navigate = useNavigate();
  const [barcode, setBarcode] = useState("");

  async function registerKit() {
    try {
      if (barcode) {
        const payload = {
          barcode: barcode,
        };
        navigate(`/register/${barcode}`);
      }
    } catch (err) {
      console.log("error creating stream:", err);
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Register New Test Kit
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Please enter the barcode of the test kit you would like to
              register, this can be found on the side of the box.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
        <div>
          <label
            htmlFor="email"
            className="block text-sm pl-2 leading-5 primary"
          >
            Barcode
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <input
              id="barcode"
              autoComplete="off"
              type="text"
              name="barcode"
              value={barcode}
              onChange={(event) => setBarcode(event.target.value)}
              required
              className={`${
                barcode && `border-primary`
              } appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md bg-white focus:outline-none focus:border-primary transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          onClick={registerKit}
        >
          Register
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default NewKitModal;

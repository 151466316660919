import { Fragment, useState } from "react";
import { Dialog, Listbox, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarDaysIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  UserCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import {
  BellIcon,
  XMarkIcon as XMarkIconOutline,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import DnaLogo from "../../Images/dna.webp";
import DnaWhiteLogo from "../../Images/dna-white.webp";

const fnavigation = {
  solutions: [
    { name: "Home", href: "https://donotage.org" },
    { name: "Shop", href: "https://donotage.org/products/" },
    {
      name: "Partnership Program",
      href: "https://donotage.org/partnership-program",
    },
    { name: "Science", href: "https://donotage.org/science/" },
    { name: "About Us", href: "https://donotage.org/about-us/" },
    { name: "Blogs", href: "https://donotage.org/blogs/" },
    { name: "Contact Us", href: "https://donotage.org/contact-us/" },
  ],
  support: [
    { name: "Contact Us", href: "https://donotage.org/contact-us/" },
    {
      name: "Work With Us",
      href: "https://donotage.org/work-with-us/",
    },
    {
      name: "Frequently Asked Questions",
      href: "https://donotage.org/frequently-asked-questions/",
    },
    { name: "Returns", href: "https://donotage.org/refund_returns/" },
    { name: "Privacy Policy", href: "https://donotage.org/privacy-policy/" },
    { name: "Cookie Policy", href: "https://donotage.org/cookie-policy/" },
  ],
  legal: [
    { name: "Log In", href: "https://donotage.org/log-in/" },
    { name: "Registration", href: "https://donotage.org/registration/" },
    { name: "Cart", href: "https://donotage.org/cart/" },
  ],
};

const navigation = [
  { name: "Your Test Results", href: "/dashboard" },
  { name: "DoNotAge.org", href: "https://donotage.org" },
  { name: "Logout", href: "/logout" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Layout = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="relative inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <button
              type="button"
              className="-m-3 p-3 md:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
            <img className="h-8 w-auto" src={DnaLogo} alt="DoNotAge" />
          </div>
          <nav className="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
            {navigation.map((item, itemIdx) => (
              <a key={itemIdx} href={item.href}>
                {item.name}
              </a>
            ))}
          </nav>
          <div className="flex flex-1 items-center justify-end gap-x-8"></div>
        </div>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIconOutline className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="-ml-0.5">
                <a href="/" className="-m-1.5 block p-1.5">
                  <span className="sr-only">DoNotAge.org</span>
                  <img className="h-8 w-auto" src={DnaLogo} alt="" />
                </a>
              </div>
            </div>
            <div className="mt-2 space-y-2">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      {children}
      <footer className="bg-black" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <img className="h-22" src={DnaWhiteLogo} alt="DoNotAge.org" />
              <p className="text-sm leading-6 text-white text-xl font-semibold">
                DONOTAGE.ORG
              </p>
              <div className="flex space-x-6"></div>
            </div>
            <div className="mt-16 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Navigation
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {fnavigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Useful Links
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {fnavigation.support.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    User
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {fnavigation.legal.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
            <p className="text-xs leading-5 text-gray-400">
              &copy; 2023 DoNotAge.org. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;

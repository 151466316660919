import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Omega from "../../Images/omega.webp";
import DnaLogo from "../../Images/dna.webp";
import DnaWhiteLogo from "../../Images/dna-white.webp";

const TestKitCard = ({ item, slug }) => {
  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="flex-shrink-0">
        <Link to={`/report/${slug}/${item.id}`} className="mt-2 block">
          <div className="h-48 w-full bg-gray-300 relative pt-14 text-center">
            <img className="h-8 w-auto mx-auto" src={DnaWhiteLogo} alt="" />
            <br />
            <span className="text-white font-bold text-xs text-center">
              DONOTAGE.ORG
            </span>
          </div>
        </Link>
      </div>
      <div className="flex flex-1 flex-col justify-between bg-white p-6">
        <div className="flex-1">
          <div className="grid grid-cols-3">
            <div className="col-span-2">
              <p className="text-sm font-medium text-gray-400">
                {moment(item.collected).format("ddd DD MMMM YY")}
              </p>
            </div>
            <div>
              <p className="text-sm text-right font-semibold text-gray-400 uppercase">
                {slug === "v" ? "Vitamin D" : "Omega 3"}
              </p>
            </div>
          </div>{" "}
          <Link to={`/report/${slug}/${item.id}`} className="mt-2 block">
            <p className="text-xl font-semibold text-gray-900">
              {item.barcode}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TestKitCard;

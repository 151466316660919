import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { logout } from "../../utils/auth";
export default function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate("/dashboard");
  }, []);

  return (
    <>
      <Layout>
        <main>
          <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
            <div className="text-left py-16">
              <p></p>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

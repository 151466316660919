import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { login } from "../../utils/auth";

function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("u") || "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const handleLogin = async () => {
    if (email && password) {
      setLoading(true);

      login({ email, password }).then((session) => {
        setLoading(false);
        if (session && session.success) {
          navigate("/dashboard");
        } else {
          setFailed(true);
        }
      });

      /*
        setLoading(false);
        if (data && data.success) {
          navigate("/dashboard");
        } else {
          setFailed(true);
        }
        */
      //});
    }
  };

  useEffect(() => {
    if (userId) {
      let logThisUserIn = {
        id: userId,
        firstname: searchParams.get("f") || "",
        lastname: searchParams.get("l") || "",
        email: searchParams.get("e") || "",
      };
      localStorage.setItem("dnaUser", JSON.stringify(logThisUserIn));
      navigate("/dashboard");
    }

    setFailed(false);
  }, [email, password]);

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full login-frame">
        <div className="pb-8">
          <img className="h-20 mx-auto" src="/logo.svg" alt="DoNotAge" />
        </div>
        <div className="bg-white pt-14 pb-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <div>
            <label
              htmlFor="email"
              className="block text-sm pl-2 leading-5 primary"
            >
              Username or Email Address
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                autoComplete="off"
                type="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
                className={`${
                  email && `border-primary`
                } appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md bg-white focus:outline-none focus:border-primary transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          <div className="mt-6">
            <label
              htmlFor="password"
              className="block text-sm pl-2 leading-5 primary"
            >
              Password
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="password"
                autoComplete="off"
                type={passwordType}
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
                className={`${
                  password && `border-primary`
                } appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-primary transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          {failed && (
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm leading-5 text-yellow-700">
                    Invalid username/password.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="mt-6 relative text-center">
            {loading ? (
              <ReactLoading
                type="bars"
                color="#000"
                height={"40px"}
                className="mx-auto"
              />
            ) : (
              <button
                type="submit"
                onClick={handleLogin}
                className="mx-auto bg-black text-white font-bold py-2 px-4 shadow-sm"
              >
                Log In
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

const VitaminDExplainer = () => {
  return (
    <>
      <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4"></div>
      <p className="mb-6">
        The Vitamin D test measures the level of total Vitamin D in your blood
        and is equivalent to 25-hydroxyvitamin D2 and D3 in plasma. It reflects
        your Vitamin D status over the past 1-2 months. As a part of a healthy
        lifestyle, a Vitamin D blood level in the 30-50 ng/mL range may help
        support metabolic, heart, brain and immune health. Vitamin D blood
        levels >100 ng/mL are above the recommended range and may lead to
        toxicity symptoms. Most people can achieve and maintain a desirable
        Vitamin D level through lifestyle habits like sun exposure, diet, and
        supplementation.
      </p>
      <p className="mb-6">
        Vitamin D3 is produced by our body when our skin is exposed to sunlight,
        unprotected by sunscreen. Our bodies are very efficient when it comes to
        producing Vitamin D3 this way. For example, our body can produce ~200
        international units (IU) of Vitamin D3 after brief UVB sunlight exposure
        of the arms and face (~5 minutes, without sunscreen). The precise amount
        of Vitamin D3 produced will vary depending on factors such as skin
        pigmentation, geographical location, season, and time of the day. Please
        consult with your doctor before increasing unprotected sun exposure.
      </p>
      <p className="mb-6">
        The best dietary sources of Vitamin D come from fish like salmon, tuna,
        and mackerel, and small amounts are also found in mushrooms and egg
        yolks. Fortified dairy products like milk are an important dietary
        source of Vitamin D, but the amount of Vitamin D present in these foods
        is low. Overall, there are very few foods in nature that contain high
        amounts of Vitamin D.
      </p>
      <p className="mb-6">
        Vitamin D supplements are an effective way to achieve and maintain a
        desirable Vitamin D level. Most pharmacies carry Vitamin D in doses of
        400 IU to 2,000 IU per tablet, but some formulations are as high as
        10,000 IU per tablet. There are virtually no side effects with Vitamin D
        when taken in appropriate doses. Vitamin D toxicity can occur with
        excessive supplementation and results in calcium levels becoming too
        high, but this is extremely rare.
      </p>
      <p className="mb-6">
        The amount of Vitamin D needed to raise the blood Vitamin D level into
        the desirable range is different for everybody. Many factors – age, sex,
        weight, genetics, geographical location, skin pigmentation, medications,
        and other medical conditions – can all influence the body’s response to
        Vitamin D. Still, we can provide an estimate, based on research, of how
        much Vitamin D you may need to raise your level into the desirable range
        given your current Vitamin D level.
      </p>
      <p className="mb-6">
        Please consult your doctor before making any changes to your diet,
        supplementation regimen, or unprotected sun exposure. If you increase
        your intake of Vitamin D or sun exposure, your Vitamin D level will
        begin to slowly go up within a few days but will continue to change for
        several months. We recommend that you re-measure your Vitamin D level
        every 3-4 months while adjusting your intake until you reach the
        desirable range. Once you reach the desirable range for Vitamin D, we
        recommend that you re-test every 6 months and adjust your intake
        accordingly.
      </p>
    </>
  );
};

export default VitaminDExplainer;

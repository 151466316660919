import { Route, Redirect, Navigate } from "react-router-dom";
import { isLoggedIn } from "../../utils/auth";

export default function ProtectedRoute({ children, level }) {
  const isAuthenticated = isLoggedIn();

  return isAuthenticated ? (
    <div className="protected">{children}</div>
  ) : (
    <Navigate
      to={{
        pathname: "/",
      }}
    />
  );
}
